class Env {
  constructor(){}

  env = {
    process: process.env.REACT_APP_process,
    processhttp: process.env.REACT_APP_processhttp,
    processColombina: process.env.REACT_APP_processColombina,
    newProcessUrl: process.env.REACT_APP_newProcessUrl,
    toolbox: process.env.REACT_APP_toolbox,
    users: process.env.REACT_APP_users,
    instancesForm: process.env.REACT_APP_instancesForm,
    things: process.env.REACT_APP_things,
    thingsMiddleware: process.env.REACT_APP_thingsMiddleware,
    maintenance: process.env.REACT_APP_maintenance,
    archives: process.env.REACT_APP_archives,
    tables: process.env.REACT_APP_tables,
    oldtables: process.env.REACT_APP_oldtables,
    newton: process.env.REACT_APP_newton,
    integrator: process.env.REACT_APP_integrator,
    smartfactoryURL: process.env.REACT_APP_smartfactoryURL,
    stream: process.env.REACT_APP_stream,
    plantbook: process.env.REACT_APP_plantbook,
    apiLink: process.env.REACT_APP_apiLink,
    trazabilidad: process.env.REACT_APP_trazabilidad,

    public: process.env.REACT_APP_PUBLIC,
    templates: process.env.REACT_APP_TEMPLATES,
    apperator: process.env.REACT_APP_APPERATOR,
  
    notificationFirebase: process.env.REACT_APP_notificationFirebase,
    privateApiCloudMessaging: process.env.REACT_APP_privateApiCloudMessaging,

    sockets: {
      notifications: process.env.REACT_APP_NOTIFICATIONS_SOCKET,
      tasks: process.env.REACT_APP_TASKS_SOCKET,
    },
  
    firebaseConfig: {
      apiKey: process.env.REACT_APP_apiKey,
      authDomain: process.env.REACT_APP_authDomain,
      databaseURL: process.env.REACT_APP_databaseURL,
      projectId: process.env.REACT_APP_projectId,
      storageBucket: process.env.REACT_APP_storageBucket,
      messagingSenderId: process.env.REACT_APP_messagingSenderId,
      appId: process.env.REACT_APP_appId,
    },
  
    ibisa20: window.location.protocol.concat("//").concat(window.location.host),
  }

  getEnv = () => this.env

  setProcessURL = url => {
    this.env.process = url;
    this.env.newProcessUrl = url;
    this.env.processhttp = url.replace('https://', 'http://');
  }
}

const Envi = new Env();
const Environments = Envi.env;

export { Environments, Envi };
